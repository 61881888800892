import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import Wrapper from '../components/Wrapper'
import SEO from '../components/SEO'
import Hero from '../components/Hero'

const PageTitle = styled.h1`
  padding-bottom: 10px;
`

const TagListItem = ({tag, frequency}) => (
  <li><Link to={`/tags/${tag}`}>{tag}</Link> ({frequency} posts)</li>
)

const TagList = ({tagFrequency}) => (
  <ul>
    {Object.keys(tagFrequency).map(tag =>
      <TagListItem key={tag} tag={tag} frequency={tagFrequency[tag]}/>)}
  </ul>
)

class TagIndex extends React.Component {
  render() {
    const pageTitle = "Tag Index"
    const tagFrequency = this.props.pageContext.tagFrequency

    return (
      <Layout location={this.props.location}>
        <SEO title="Tag Index" />
        <Hero title={pageTitle} />

        <Wrapper>
          <TagList tagFrequency={tagFrequency} />
        </Wrapper>
      </Layout>
    )
  }
}

export default TagIndex
